import React from 'react';
import Layout from '../components/Layout';
import Contact from '../components/Contact';
import BoxMan from '../assets/images/cardboard-box-toy-figure.jpg';
import ladyYoga from '../assets/images/lady-does-yoga.jpg';
import kitchen from '../assets/images/kitchen.jpg';
import Benzo from '../assets/images/white-mercedes-benz.jpg';


const IndexPage = () => (
  <Layout>
    <article id="main">
      <header>
        {' '}
        <h2>What you get with Carlsbad Movers</h2>
        <p>These are the services included with every single move</p>
      </header>
    </article>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={BoxMan} alt="cardboard box toy figure" />
        </div>
        <div className="content">
          <h3>Professional packing service</h3>
          <p>
            Knowing how to pack is a skill many pride themselves on. And now
            when you think about it you can picture how you want each box
            packed. So if you are like most folks you probably want to pack your
            own boxes. Go for it. We will handle all the loading and unloading
            of every last box you pack. And what about packing furniture? Well
            it's a simple enought skill to learn to do Actually doing it is a
            lot of work, and because we know it needs to be done right we will
            do the big stuff for you. We send out moving pads and wrap all of
            your furniture and unboxed items so that they stay safe and secure
            on your moving truck.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={ladyYoga} alt="lady relaxing and doing yoga" />
        </div>
        <div className="content">
          <h3>Loading by movers</h3>
          <p>
            Loading all of the items from a house can take a long time, and if
            you don’t know how its not easy. Plus carrying all those heavy items
            is a good way to get a hernia, or slip a disc. Because we have moved
            thousands and thousands of people just like you, we know exactly how
            to load a moving truck. And because our movers are taken care by our
            family business your moving team is a crew of strong young men who
            look at moving houses like another day in the gym. Our movers are
            fully covered by an extensive insurance plan and we cover the costs
            of personal training and a healthy meal plan for our teams so that
            we know that any task which needs doing is one our team is ready to
            handle with ease. We all the tools and know how to stack the truck
            perfectly and make sure your items are locked in place before your
            shipment hits the road.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={kitchen} alt="a clean kitchen that movers have set up after moving " />
        </div>
        <div className="content">
          <h3>House set up by movers</h3>
          <p>
            When your moving truck arrives at your new home with all of your
            items the same moving team you met in San Diego will greet you and
            welcome you home. Each and every item will be carefully unloaded and
            checked off on the itemized inventory the logistics team makes for
            you. That way you know with 100% certainty that everything has been
            delivered. Once everything is off the truck the team will assemble
            any items which need assembly. Every last lamp, box, and picture
            will go in its proper place so that you and your family will feel at
            home from day one.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={Benzo} alt="white mercedes benz waiting for auto shipping" />
        </div>
        <div className="content">
          <h3>Premium services we offer</h3>

          <ul>
            <li><a href="/fragile-packing">Fragile Packing</a> </li>
            <li><a href="/fullpacking"> Full Packing</a></li>
            <li><a href="/whiteglove">White Glove</a></li>
            <li><a href="/piano-moving">Piano Moving</a></li>
            <li>Auto Transportation</li>
          </ul>
        </div>
      </section>
    </section>



  </Layout>
);
export default IndexPage;
